import { ActionButton } from "@able/react";
import { Link, useLocation } from "react-router-dom";
import DropDown from "../drop-down/drop-down";
import MobileNavigation from "./MobileNavigation";
import "./Navigation.scss";

const Navigation = () => {
    const { pathname } = useLocation();

    return (
        <header className="navigation-bar">
            <Link aria-label="Telstra LANES" className="logo" to="/">
                <img src="/images/logoBaseEarth60+Sky60.svg" className="telstra-icon" alt="Telstra" />
                <div className="lanes-heading" aria-hidden="true">
                    LANES
                </div>
            </Link>
            <nav className="nav-container">
                <ol className="nav-items">
                    <li>
                        <Link
                            className={`nav-item ${
                                pathname === "/services" ? `nav-item-selected` : `nav-item-unselected`
                            }`}
                            to="/services"
                        >
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={`nav-item ${
                                pathname === "/availability" ? `nav-item-selected` : `nav-item-unselected`
                            }`}
                            to="/availability"
                        >
                            Availability
                        </Link>
                    </li>
                    <li>
                        <a
                            className="nav-item nav-item-unselected"
                            href="mailto:lanesproductsupport@team.telstra.com?Subject=Portal%20Feedback"
                        >
                            Feedback
                        </a>
                    </li>
                    <li>
                        <ActionButton
                            developmentUrl="/assets/able-sprites.svg"
                            element="Link"
                            icon="Help"
                            label=""
                            to="/help-and-support"
                            variant="LowEmphasisBig"
                            aria-label="Help and Support"
                            aria-hidden="false"
                        />
                    </li>
                    <li>
                        <DropDown />
                    </li>
                </ol>
                <MobileNavigation pathname={pathname} />
            </nav>
        </header>
    );
};

export default Navigation;
