import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "@noa/web-shared-library";
import "./services-table.scss";

const ServicesTable = ({ columns, data, externalFilters }) => {
    const navigate = useNavigate();
    const [tableKey, setTableKey] = useState(0);

    const tableClickHandler = (e) => {
        const serviceInfo = data[e.id];
        navigate(`${serviceInfo.id}`);
    };

    const tableHeaders = columns.map((column) => ({ title: column }));

    const tableData = data
        ? data.map((sub, idx) => ({
              id: idx,
              data: [
                  sub.serviceNumber,
                  sub.subscriptionId,
                  sub.simId,
                  sub.isAssigned ? sub?.device?.equipmentId : "Unknown",
                  sub.isAssigned ? sub?.device?.type : "Unassigned",
                  sub.isAssigned ? `${sub?.device?.make} ${sub?.device?.model}` : "Unknown",
              ],
              onClickHandler: tableClickHandler,
          }))
        : null;

    // Force Table component to reset when filter update
    useEffect(() => {
        setTableKey((t) => t + 1);
    }, [externalFilters]);

    return (
        <div className="service-table-group">
            <div className="service-table-container">
                <Table
                    key={tableKey}
                    enableEvenOddFill
                    headers={tableHeaders}
                    rows={tableData}
                    numRowsPerPage={25}
                    ableDevUrl="/assets/able-sprites.svg"
                />
            </div>
        </div>
    );
};

export default ServicesTable;
