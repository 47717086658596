import { Column, Grid } from "@able/react";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/AbleBreadcrumbs";
import { PageHeading } from "../../common/components/page-heading/page-heading";
import useStore from "../../store/app-store";
import { decodeToken } from "../../utils/token-util";
import NetworkAvailabilityCard from "./components/availability-card-component";
import ExternalLink from "./components/external-links-component";
import GreetingIllustration from "./components/greeting-illustration-component";
import NetworkOutagesCard from "./components/outages-card-component";
import WelcomeDetails from "./components/welcome-details-component";
import ServiceSummaryCard from "./components/service-summary-component";
import DeviceSummaryCard from "./components/device-summary-card-component";
import SpeedUpliftCard from "./components/speed-uplift-card-component";
import "./dashboard-page-style.scss";
import { time } from "highcharts";

const DashboardPage = () => {
    let { firstName } = decodeToken(sessionStorage.getItem("id_token"));

    const tenancyData = useStore((state) => state.tenancyData);
    const fetchTenancy = useStore((state) => state.fetchTenancy);
    useSetBreadcrumbs([]);

    useEffect(() => {
        const controller = new AbortController();
        if (tenancyData === null) {
            fetchTenancy({ signal: controller.signal }).catch((e) => console.error(e));
        }
        return () => controller.abort();
    }, [tenancyData, fetchTenancy]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Dashboard Page"});
    }, []);

    function getGreeting() {
        let curHr = new Date().getHours();
        if (curHr < 12) return "Morning, " + firstName;
        if (curHr < 18) return "Afternoon, " + firstName;
        if (curHr < 23) return "Evening, " + firstName;
        else return "Hello, " + firstName;
    }

    return (
        <>
            <Helmet>
                <title>Telstra LANES</title>
            </Helmet>
            <Grid className="dashboard-container" padding={false}>
                <Column cols={6}>
                    <PageHeading content={getGreeting(firstName)} />
                    <WelcomeDetails tenancyName={tenancyData?.tenancyName} />
                </Column>
                <Column cols={12} bmd={6}>
                    <GreetingIllustration />
                </Column>

                <ExternalLink
                    className="external-link"
                    preLinkTxt="Useful Links"
                    linkTxt="Service usage and billing"
                    linkUrl="https://connectapp.telstra.com/"
                />

                {tenancyData && (
                    <ul style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
                        <li>
                            <article>
                                <ServiceSummaryCard tenancy={tenancyData} />
                            </article>
                        </li>
                        <li>
                            <article>
                                <SpeedUpliftCard tenancy={tenancyData} />
                            </article>
                        </li>
                        <li>
                            <article>
                                <DeviceSummaryCard tenancy={tenancyData} />
                            </article>
                        </li>
                        <li>
                            <article>
                                <NetworkAvailabilityCard />
                            </article>
                        </li>
                        <li>
                            <article>
                                <NetworkOutagesCard />
                            </article>
                        </li>
                    </ul>
                )}
            </Grid>
        </>
    );
};

export default DashboardPage;
