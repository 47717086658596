import { makeApiCall } from "../utils/request-util";

export const fetchNewToken = async () => {
    const token = sessionStorage.getItem("id_token");
    const refreshToken = sessionStorage.getItem("refresh_token");

    let options = {
        method: "POST",
        uri: "/identity/v1/token/refresh",
        data: {
            token,
            refreshToken,
        },
    }

    return await makeApiCall(options)
}