import { fetchInventoryCountData, fetchSubscriptionsData } from "../../apis/subscriptions-apis";

export const createSubscriptionSlice = (set, get) => ({
    inventoryCount: null,
    fetchInventoryCount: async (options) => {
        const tenancyId = get().tenancyData.tenancyId;
        if (tenancyId) {
            const data = await fetchInventoryCountData(tenancyId, options);
            set((state) => ({ ...state, inventoryCount: data }));
        }
    },

    subscriptions: null,
    fetchSubscriptions: async (options) => {
        const tenancyId = get().tenancyData.tenancyId;
        if (tenancyId) {
            const data = await fetchSubscriptionsData(tenancyId, options);
            set((state) => ({ ...state, subscriptions: data }));
        }
    },
    clearSubscriptions: () => set((state) => ({...state, subscriptions: null})),
});