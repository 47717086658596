import "./chart-information.component-style.scss";

const EnterpriseInfoSub = () => {
    return (
        <>
            <div className="row">
                <h2 className="info">If there is missing data in the above reporting, this could be because:</h2>
                <ul className="justify-content">
                    <li>
                        The mobile service has not been used yet. The reporting system takes up to two days for data to
                        display following first usage of the service.
                    </li>
                    <li>
                        The mobile service has not been used for that billing period. Please note that daily data resets
                        on the first day of each billing period, so data starts displaying from the second day of the
                        billing period.
                    </li>
                    <li>The mobile service has been connected to Wi-Fi and not our mobile network.</li>
                    <li>The user's SIM is in a 3G-only device (LANES only works on our 4G and 5G networks).</li>
                    <li>
                        Our reporting systems are not always fault-free. In some circumstances data can be missing from
                        the daily or hourly report due to a system or network-related reporting fault.There is a known
                        problem we are working to resolve where reporting will likely not appear for data sessions that
                        stay connected for several hours or more. This affects routers but should not affect handhelds,
                        tablets or other MBB devices which typically return to idle after sending/receiving data in
                        order to preserve battery.
                    </li>
                </ul>
            </div>
            <div className="row">
                <h2 className="info">Things you need to know:</h2>
                <div className="justify-content-disclaimer">
                    Telstra customers with capable Category 20 devices can experience speeds of 5Mbps-500Mbps in the
                    download and 1Mbps-40Mbps in the upload; Category 16 or 18 devices can experience typical speeds of
                    5-300Mbps in the download and 1-20Mbps in the upload; customers with Category 11 devices can
                    experience typical speeds of 5-200Mbps in the download and 1-10Mbps in the upload; customers with
                    Category 9 devices can experience typical speeds of 5-150Mbps in the download and 1-10Mbps in the
                    upload; customers with Category 6 devices can experience typical speeds of 2-100Mbps in the download
                    and 1-10Mbps in the upload; and those with compatible Category 4 devices can experience typical
                    speeds of 2-75Mbps in the download and 1-10Mbps in the upload in all 4G coverage areas.
                    <br /> <br />
                    In 5G Coverage areas, Telstra customers with capable 5G devices can experience speeds of
                    10Mbps-1Gbps in the download and 1Mbps-40Mbps in the upload. In 5G mmWave Coverage areas, Telstra
                    customers with capable mmWave 5G devices can experience speeds of 500Mbps-2.5Gbps in the download
                    and 5Mbps-50Mbps in the upload.
                    <br /> <br />
                    Speeds may vary due to factors such as location, distance from the base station, local conditions,
                    concurrent users, hardware and software configuration and download/upload destination. To check
                    current coverage in a given area, see our{" "}
                    <a
                        href="http://www.telstra.com/coverage"
                        className="inlineAnchor"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        coverage maps
                    </a>
                    .
                </div>
            </div>
        </>
    );
};

export default EnterpriseInfoSub;
