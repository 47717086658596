import { makeApiCall } from "../utils/request-util";

export const fetchAvailabilityData = async (opts = {}) => {

    let options = {
        uri: "/observability/v1/network-availability",
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchAvailabilityHistoryData = async (opts = {}) => {

    let options = {
        uri: "/observability/v1/network-availability",
        ...opts
    }

    return await makeApiCall(options)
}
