import { Pictograms } from "telstra-ui/components/svg/Pictograms";
import "./throughput-chart-no-results.scss";

const ThroughputChartNoResults = (props) => {
    return (
        <>
            <div className="row no-result-div">
                <div className="no-result-found">No results found</div>
                <div className="no-result-message">
                    We were not able to find any data for this customer or time period.
                </div>
                <Pictograms name="picto-search" className="picto-svg-class picto-sizing" />
            </div>
        </>
    );
};

export default ThroughputChartNoResults;
