import "./help-information-style.scss";

const SpeedUpliftInfo = () => {
    return (
        <div className="row">
            <h2 className="info">Speed Uplift</h2>
            <ul className="justify-content">
                <li>
                    The speed uplift figure refers to the estimated increase in downlink throughput (Mbps) averaged
                    across all active user data sessions in the reporting period.
                </li>
                <li>
                    The LANES Quality Of Service policy provides a typical uplift in throughput of 15% to 100%.
                    Generally speaking, as cell load increases there will be greater speed uplift observed as LANES QoS
                    kicks in and may exceed 100% for a particular service. Conversely at relatively low cell loads,
                    speed uplift may be less than 15% as there is less contention for cell resources.
                </li>
            </ul>
        </div>
    );
};

export default SpeedUpliftInfo;
