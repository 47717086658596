import { IconButton } from "@able/react";
import { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import "./info-tooltip.scss";

const InfoTooltip = ({ id, label, children }) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    const tooltipRef = useRef(null);
    const buttonRef = useRef(null);

    function calcTooltipLocation() {
        const boundingBox = buttonRef.current.getBoundingClientRect();
        const { top, right } = boundingBox;

        const tooltipHeight = tooltipRef.current.clientHeight;
        const buttonHeight = buttonRef.current.clientHeight;

        const offset = (tooltipHeight - buttonHeight) / 8;

        tooltipRef.current.style.left = `${right - 35}px`;
        tooltipRef.current.style.top = `${top + offset}px`;
        tooltipRef.current.style.bottom = "auto";
    }

    const handleOnKeyDown = (event) => {
        if (event.key === "Escape") {
            setOpenTooltip(false);
        }
    };

    // On mount set aria-hidden to true on svg, this perhaps should be brought up with Able.
    useEffect(() => {
        buttonRef.current.children[0].ariaHidden = "true";
    }, []);

    // Handle resize and scroll events for tooltips
    useEffect(() => {
        window.addEventListener("resize", calcTooltipLocation);
        window.addEventListener("scroll", calcTooltipLocation);
        window.addEventListener("keydown", handleOnKeyDown);
        return () => {
            window.removeEventListener("resize", calcTooltipLocation);
            window.removeEventListener("scroll", calcTooltipLocation);
            window.removeEventListener("keydown", handleOnKeyDown);
        };
    }, []);

    // Handle click events outside of tooltip + button.
    useEffect(() => {
        function handleClickEvent(event) {
            if (tooltipRef.current && buttonRef.current) {
                const isTooltipClick = tooltipRef.current.contains(event.target);
                const isButtonClick = buttonRef.current.contains(event.target);

                if (!isButtonClick && !isTooltipClick) {
                    setOpenTooltip(false);
                }
            }
        }

        document.addEventListener("click", handleClickEvent, true);

        return () => {
            document.removeEventListener("click", handleClickEvent, true);
        };
    }, []);

    // TODO: Change the Info icon to InformationAlt when Able resolve the rendering issue.
    // Currently the InformationAlt icon does not render correctly with the IconButton component.
    return (
        <div onKeyDown={handleOnKeyDown}>
            <IconButton
                className="able-icon-button-overrides"
                ref={buttonRef}
                aria-describedby={id}
                aria-expanded={openTooltip}
                aria-label={`${label} tooltip`}
                icon="Info"
                developmentUrl="/assets/able-sprites.svg"
                events={{
                    onClick: () => {
                        calcTooltipLocation();
                        setOpenTooltip(true);
                    },
                    onFocus: () => {
                        calcTooltipLocation();
                        setOpenTooltip(true);
                    },
                    onKeyDown: handleOnKeyDown,
                }}
            />
            <div
                id={id}
                aria-live="polite"
                aria-hidden={openTooltip ? false : true}
                ref={tooltipRef}
                role="tooltip"
                className={`tooltip ${openTooltip && `show-tooltip`}`}
            >
                <button
                    type="button"
                    className="tooltip-close"
                    onClick={() => setOpenTooltip(!openTooltip)}
                    aria-label="Close tooltip"
                >
                    ✕
                </button>
                <div className="tooltip-text">{children}</div>
            </div>
        </div>
    );
};

InfoTooltip.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};

export default InfoTooltip;
