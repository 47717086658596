import { MessageInline } from "@able/react";
import "./legal-disclaimer-component-style.scss";

const LegalDisclaimer = () => {
    return (
        <MessageInline
            description='legal disclaimer about 4G/5G usage'
            variant='Information'
            developmentUrl="/assets/able-sprites.svg"
            className="legal-disclaimer-container"
        >
            <div id="legal-disclaimer" tabIndex="-1" role="region">
                <div className="legal-disclaimer-content">
                    <p>
                        * The results displayed show the speed uplift for the service while the device has been using Telstra's 4G and 5G networks. 
                        For 5G enabled devices connected to our 5G network, the device can use aspects of both our 4G and 5G networks simultaneously.
                    </p>
                    <p>
                        Note the above reporting represents uplifts delivered via our 4G network and some aspects of our 5G network, 
                        meaning the received performance may be faster or slower than what is shown. 
                    </p>
                    <p>
                        We anticipate full 5G speed uplift reporting will be available soon.
                    </p>
                </div>
            </div>
        </MessageInline>
    );
};

export default LegalDisclaimer;