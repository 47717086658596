import axios from "axios";
import { getProperty } from "../utils/config-util";

const apiGatewayUrl = getProperty("API_GATEWAY_URL", "https://noa-gateway-amqp-dev.apps.np.sdppcf.com/api/lanes");

export const login = async (params, options) => {
	const response = await axios({
		method: "POST",
		url: `${apiGatewayUrl}/identity/v1/login/external`,
		params: params,
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
		...options,
	});

	const data = response.data?.data;
	return data;
}

