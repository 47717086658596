export function filterInventory(subscriptions, fieldValue) {
    let matches = [];
    let fieldValueLower = fieldValue.toLowerCase();

    let filteredInventory = subscriptions.filter((sub) => {
        const { simId, subscriptionId, serviceNumber, isAssigned, device } = sub;

        if (simId && simId.toLowerCase().includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: simId });
            return true;
        }

        if (subscriptionId && subscriptionId.toLowerCase().includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: subscriptionId });
            return true;
        }

        if (serviceNumber && serviceNumber.toLowerCase().includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: serviceNumber });
            return true;
        }

        if (isAssigned && device && device?.equipmentId.toLowerCase().includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: device?.equipmentId });
            return true;
        }

        if (isAssigned && device && device?.type.toLowerCase().includes(fieldValueLower)) {
            if(!matches.some(obj => obj.value.toLowerCase() === device?.type.toLowerCase()))
            {
                matches.push({ id: sub.id, value: device?.type });
                return true;
            }
        }

        if (isAssigned && device && device?.make.toLowerCase().includes(fieldValueLower)) {
            if(!matches.some(obj => obj.value.toLowerCase() === device?.make.toLowerCase()))
            {
                matches.push({ id: sub.id, value: device?.make });
                return true;
            }
        }

        if (isAssigned && device && device?.model.toLowerCase().includes(fieldValueLower)) {
            if(!matches.some(obj => obj.value.toLowerCase() === device?.model.toLowerCase()))
            {
                matches.push({ id: sub.id, value: device?.model });
                return true;
            }
        }

        return false;
    });

    return { filteredInventory, matches };
}
