import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Add your plugins here
dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export default dayjs;