import jwtDecode from "jwt-decode";
import dayjs from "./dayjs-util";

export const decodeToken = (token) => {
    const tokenData = jwtDecode(token);
    const [cidn, authLevel] = tokenData.roles[0].value.split(":");

    return { ...tokenData, cidn, authLevel };
};

export const validateToken = (token) => {
    if (!token) {
        // No tokens in session storage!
        return false;
    }

    try {
        let { exp } = jwtDecode(token);
        let d = new Date(0).setUTCSeconds(exp); // The 0 there is the key, which sets the date to the epoch
        let now = dayjs.utc();
        let expiration = dayjs.utc(d);

        return now.isSameOrBefore(expiration);
    } catch (e) {
        // An error occurred when validating token
        return false;
    }
};

export const checkTokens = () => {
    const idToken = sessionStorage.getItem("id_token");
    const refreshToken = sessionStorage.getItem("refresh_token");

    const validIdToken = validateToken(idToken);
    const validRefreshToken = validateToken(refreshToken);

    // You can only refresh when you have the id_token and refresh_token
    // Both of these must not be expired.
    if (validIdToken && validRefreshToken) {
        return true;
    } else return false;
};
