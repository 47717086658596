import { useEffect, useRef, useState } from "react";
import { useNavigatedOutside } from "./useNavigatedOutside";

import "./styles.scss";

const SearchCombobox = ({ matches, fieldValue, onFieldChange, helperText }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(fieldValue ?? "");
    const [options, setOptions] = useState([]);

    const listRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(null);

    const comboboxRef = useNavigatedOutside({ callback: () => setOpen(false), listenClicks: true });
    const inputRef = useNavigatedOutside({ callback: () => setOpen(false), listenTabs: true });

    const getOptions = () => {
        return matches.map(({ id, value }) => ({
            displayText: value,
            itemId: id,
            subheader: false,
            disabled: false,
        }));
    };

    const openOptions = () => {
        if (value !== "" && !open) setOpen(true);
    };

    const clearSearch = () => {
        setOpen(false);
        setValue("");
        onFieldChange("");
        setOptions([]);
        if (inputRef && inputRef.current) inputRef.current.focus();
    };

    const inputOnChange = (value) => {
        if (value !== "") {
            setOpen(true);
        }
        setValue(value);
        onFieldChange(value);
    };

    /**
     * Matches the letters typed into the input with the matched result and
     * adds bold styling to the matches letters typed in search. This is to
     * align with Telstra UI Enterprise Search component styling.
     *
     * Ben Nicholls wrote this magic, reach out to him for questions.
     *
     * @param {string} text the content in list element
     * @returns styled text
     */
    const styleText = ({ text }) => {
        const matchInput = text;
        const textEntry = value;

        // Normalise entries
        const lowerCaseMatch = matchInput.toLowerCase();
        const lowerCaseEntry = textEntry.toLowerCase();
        const startIndex = lowerCaseMatch.indexOf(lowerCaseEntry);
        if (startIndex !== -1) {
            const endIndex = startIndex + textEntry.length;
            const prefix = matchInput.substring(0, startIndex);
            const bold = matchInput.substring(startIndex, endIndex);
            const suffix = matchInput.substring(endIndex);

            return (
                <>
                    {prefix}
                    <b>{bold}</b>
                    {suffix}
                </>
            );
        } else {
            return <>{text}</>;
        }
    };

    const upArrowControls = ({ event }) => {
        if (options.length > 0) {
            event.preventDefault();
            if (listRef.current) {
                if (currentIndex === null) {
                    setCurrentIndex(listRef.current.children.length - 1);
                    listRef.current.children[listRef.current.children.length - 1].focus();
                } else if (currentIndex > 0) {
                    setCurrentIndex(currentIndex - 1);
                    listRef.current.children[currentIndex - 1].focus();
                } else if (currentIndex === 0) {
                    setCurrentIndex(listRef.current.children.length - 1);
                    listRef.current.children[listRef.current.children.length - 1].focus();
                }
            }
        }
    };

    const downArrowControls = ({ event }) => {
        if (options.length > 0) {
            event.preventDefault();
            if (listRef.current) {
                if (currentIndex === null) {
                    setCurrentIndex(0);
                    listRef.current.children[0].focus();
                } else if (currentIndex < listRef.current.children.length - 1) {
                    setCurrentIndex(currentIndex + 1);
                    listRef.current.children[currentIndex + 1].focus();
                } else if (currentIndex === listRef.current.children.length - 1) {
                    setCurrentIndex(0);
                    listRef.current.children[0].focus();
                }
            }
        }
    };

    const keyDownHandler = ({ event, value, element }) => {
        if (!event) return;
        if (
            element === "ul" &&
            ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90))
        ) {
            inputRef.current.focus();
            setCurrentIndex(null);
            return;
        }
        switch (event.key) {
            case "Enter":
                if (value) {
                    setValue(value);
                    onFieldChange(value);
                }
                setOpen(false);
                inputRef.current.focus();
                break;

            case "Down":
            case "ArrowDown":
                openOptions();
                downArrowControls({ event });
                break;

            case "Up":
            case "ArrowUp":
                openOptions();
                upArrowControls({ event });
                break;

            case "Esc":
            case "Escape":
                if (open) {
                    setOpen(false);
                }
                break;

            case "Tab":
                if (element === "li") {
                    setValue(value);
                    setOpen(false);
                }
                break;
            case "Backspace":
                if (element === "ul" || element === "li") {
                    inputRef.current.focus();
                    setCurrentIndex(null);
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (value !== "") {
            const filteredOptions = getOptions();
            setOptions(filteredOptions);
        } else {
            setOptions([]);
            setCurrentIndex(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches, value]);

    return (
        <div ref={comboboxRef} className="search-combobox-container">
            <label htmlFor="search-input">Filter services</label>
            {helperText && <span id="search-helper-text">{helperText}</span>}
            <div className="combobox">
                <div className="group">
                    <input
                        id="search-input"
                        ref={inputRef}
                        type="text"
                        role="combobox"
                        aria-describedby="search-helper-text"
                        aria-autocomplete="list"
                        aria-expanded={(open && options.length > 0) ? true : false}
                        aria-controls="search-options"
                        aria-activedescendant={currentIndex === null ? "" : "list-item-" + currentIndex}
                        placeholder="Search"
                        autoComplete="off"
                        value={value}
                        onChange={(e) => inputOnChange(e.target.value)}
                        onKeyDown={(event) => keyDownHandler({ event })}
                        onFocus={() => {
                            if (value !== "") setOpen(true);
                        }}
                        onClick={() => {
                            openOptions();
                            setCurrentIndex(null);
                        }}
                    />

                    {value !== "" && (
                        <button className={`${value !== "" ? "show" : "hide"}`} onClick={clearSearch}>
                            <svg
                                className={`able-icon able-icon--24`}
                                role="img"
                                aria-hidden="false"
                                aria-label="Clear search"
                                focusable="false"
                            >
                                <use href="./assets/able-sprites.svg#Close"></use>
                            </svg>
                        </button>
                    )}
                    <div className="search-icon-container">
                        <svg
                            className="able-icon able-icon--24"
                            role="img"
                            aria-hidden="true"
                            aria-label="Search"
                            focusable="false"
                        >
                            <use href="./assets/able-sprites.svg#Search"></use>
                        </svg>
                    </div>
                </div>
                {open && (
                    <ul
                        ref={listRef}
                        id="search-options"
                        role="listbox"
                        aria-label="Services"
                        onKeyDown={(event) => keyDownHandler({ event, element: "ul" })}
                    >
                        {options.map((e, idx) => (
                            <li
                                id={"list-item-" + idx}
                                role="option"
                                key={idx}
                                tabIndex={idx === currentIndex ? "0" : "-1"}
                                aria-selected={idx === currentIndex}
                                onKeyDown={(event) => keyDownHandler({ event, value: e.displayText, element: "li" })}
                                onClick={() => {
                                    setValue(e.displayText);
                                    setOpen(false);
                                    inputRef.current.focus();
                                }}
                            >
                                {styleText({ text: e.displayText })}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SearchCombobox;
