import { useQuery } from "@tanstack/react-query";
import { Icon } from "@able/react";
import { fetchActiveDevicesCount } from "../../../apis/tenancy-apis";
import { Link } from "react-router-dom";
import SummaryCard from "./SummaryCard/SummaryCard";

const DeviceSummaryCard = ({ tenancy }) => {
    const { data: deviceSummaryDetails, isLoading } = useQuery(["DevicesCount", tenancy], () =>
        fetchActiveDevicesCount(tenancy?.tenancyId)
    );

    const tooltipContent = (
        <>
            Provides a breakdown by device category for LANES services seen active on the network. Learn more about{" "}
            <Link to="/help-and-support#device-summary-info">device summary</Link>
        </>
    );
    const headers = ["Service type", "Services"];
    const rows = [
        [
            <>
                <Icon size="22" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                Handsets
            </>,
            deviceSummaryDetails?.handset,
        ],
        [
            <>
                <Icon size="22" icon="Modem" developmentUrl="/assets/able-sprites.svg" />
                Routers
            </>,
            deviceSummaryDetails?.router,
        ],
        [
            <>
                <Icon size="22" icon="Tablet" developmentUrl="/assets/able-sprites.svg" />
                Tablets
            </>,
            deviceSummaryDetails?.tablets,
        ],
        [
            <>
                <Icon size="22" icon="Category" developmentUrl="/assets/able-sprites.svg" />
                Other devices
            </>,
            deviceSummaryDetails?.others,
        ],
    ];

    return (
        <SummaryCard
            showLoading={isLoading}
            title="Device summary"
            subtitle="All services"
            tooltipLabel="Device summary information"
            tooltipContent={tooltipContent}
            loadingText="Loading data..."
            data={{ headers, rows }}
        />
    );
};

export default DeviceSummaryCard;
