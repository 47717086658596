import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          refetchOnReconnect: false,
          refetchOnMount: false,
          staleTime: Infinity,
        },
      },
});

const AppProvider = ({children}) => (
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>{children}</BrowserRouter>
    </QueryClientProvider>
)

export default AppProvider;