import ReactGA from "react-ga4";
import { Column, Grid, Icon } from "@able/react";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { Helmet } from "react-helmet";
import "./help-page-style.scss";
import { PageHeading } from "../../common/components/page-heading/page-heading";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/AbleBreadcrumbs";
import ServiceSummaryInfo from "./components/service-summary-component";
import SpeedUpliftInfo from "./components/speed-uplift-component";
import DeviceSummaryInfo from "./components/device-summary-component";
import NetworkAvailabilityInfo from "./components/network-availability-component";
import { useEffect } from "react";

const HelpPage = () => {
    useSetBreadcrumbs([{ label: "Dashboard", to: "/" }]);

    useEffect(() => {
        const hash = window.location.hash.slice(1);
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Help Page"});
    }, [])

    return (
        <>
            <Helmet>
                <title>Help and Support - Telstra LANES</title>
            </Helmet>
            <Grid>
                <Column cols={12}>
                    <PageHeading content="Help and support" />
                </Column>
                <Column cols={12}>
                    <div className="sub-header-parent">
                        <span className="sub-header">
                            We're here to help you with any questions you have about LANES.
                        </span>
                    </div>
                </Column>
                <Column cols={12}>
                    <div className="help-content info-padding">
                        <div className="icon-bg">
                            <SvgIcon name="icon-contact-customer-contact-solution" className="help-support-icon" />
                        </div>
                        <div className="detail-content">
                            <span className="section-title">For all enquiries</span>
                            <dl>
                                <div className="all-enquiries-content">
                                    <dt>
                                        <Icon
                                            icon="SupportPhone"
                                            size="24"
                                            screenReaderContent=""
                                            developmentUrl="./assets/able-sprites.svg"
                                        />
                                        Dedicated Help Desk
                                    </dt>
                                    <dd>1800 874 956</dd>
                                    <dd>Mon-Fri, 9am-5pm</dd>
                                    <dd>
                                        or{" "}
                                        <a
                                            className="inline-anchor"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://servicecentral.service-now.com/ism/"
                                        >
                                            submit a support request
                                        </a>
                                    </dd>
                                </div>
                                <div className="mail-enquiries-content">
                                    <dt>
                                        <Icon
                                            icon="Mail"
                                            size="24"
                                            screenReaderContent=""
                                            developmentUrl="./assets/able-sprites.svg"
                                        />
                                        Email support
                                    </dt>
                                    <dd>
                                        <a className="mail-address" href="mailto:lanes@team.telstra.com">
                                            lanes@team.telstra.com
                                        </a>
                                    </dd>
                                </div>
                                <div className="all-enquiries-content">
                                    <dt>
                                        <Icon
                                            icon="GiveFeedback "
                                            size="24"
                                            screenReaderContent=""
                                            developmentUrl="./assets/able-sprites.svg"
                                        />
                                        Feedback
                                    </dt>
                                    <dd>
                                        <a
                                            className="mail-address"
                                            href="mailto:lanesproductsupport@team.telstra.com?Subject=Portal%20Feedback"
                                        >
                                            lanesproductsupport@team.telstra.com
                                        </a>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </Column>
            </Grid>
            <br />
            <div className="row">
                <h2 className="lanes-header">LANES terms explained</h2>
            </div>
            <div id="service-summary-info">
                <ServiceSummaryInfo />
            </div>
            <div id="speed-uplift-info">
                <SpeedUpliftInfo />
            </div>
            <div id="device-summary-info">
                <DeviceSummaryInfo />
            </div>
            <div id="network-availability-info">
                <NetworkAvailabilityInfo />
            </div>
        </>
    );
};

export default HelpPage;
