import "./help-information-style.scss";

const ServiceSummaryInfo = () => {
    return (
        <div className="row">
            <h2 className="info">Service Summary</h2>
            <ul className="justify-content">
                <li>
                    This summary shows the total number of LANES services provisioned for the customer.
                </li>
                <li>
                	Online today is the number of services seen active on the network since midnight.
                </li>
                <li>
                	Online yesterday is the number of services seen active on the network for the 24 hours on the previous day.
                </li>
                <li>
                	Offline is the number of LANES services not seen today or yesterday active on the network.
                </li>
            </ul>
        </div>
    );
};

export default ServiceSummaryInfo;
