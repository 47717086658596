// Function to format downlink values
const formatDownlink = (downlink) => {
    return downlink ? (downlink / 1000).toFixed(2) : null;
};

// Function to calculate average improvement
export const calculateAveragePerformance = (networkDownlink, subscriptionDownlink) => {
    if (networkDownlink === null || subscriptionDownlink === null) {
        return null;
    }

    return (((subscriptionDownlink - networkDownlink) / networkDownlink) * 100).toFixed(2);
};

// Function to find the most recent timestamp
const findMostRecentTimestamp = (data) => {
    const timestamps = data.series.map((entry) => entry.timestamp);
    return timestamps.reduce(
        (mostRecent, current) =>
            mostRecent ? (new Date(current) > new Date(mostRecent) ? current : mostRecent) : current,
        null
    );
};

// Function to process data and return results for UI display
export const processMonthlyData = (subscriptionData, networkData) => {
    // Check if either subscriptionData or networkData is undefined
    if (!subscriptionData || !networkData) {
        return {
            timestamp: null,
            networkDownlink: null,
            subscriptionDownlink: null,
        };
    }

    // Find the most recent timestamp in both datasets
    const mostRecentTimestampSubscription = findMostRecentTimestamp(subscriptionData);
    const mostRecentTimestampNetwork = findMostRecentTimestamp(networkData);

    // Determine the final timestamp (most recent of both timestamps)
    const finalTimestamp =
        mostRecentTimestampSubscription && mostRecentTimestampNetwork
            ? //compares the timestamps after converting them to Date objects
              new Date(mostRecentTimestampSubscription) > new Date(mostRecentTimestampNetwork)
                ? mostRecentTimestampSubscription
                : mostRecentTimestampNetwork
            : //returns the timestamp that is defined, null is returned if both timestamps are undefined
              mostRecentTimestampSubscription || mostRecentTimestampNetwork;

    // Find the corresponding downlink for the final timestamp in each dataset
    const downlinkSubscription =
        subscriptionData.series.find((entry) => entry.timestamp === finalTimestamp)?.throughput.downLink || null;
    const downlinkNetwork =
        networkData.series.find((entry) => entry.timestamp === finalTimestamp)?.throughput.downLink || null;

    // Return the results
    return {
        networkDownlink: formatDownlink(downlinkNetwork || downlinkSubscription),
        subscriptionDownlink: formatDownlink(downlinkSubscription || downlinkNetwork),
    };
};
