import { IconButton } from "@able/react";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store/app-store";

const MobileNavigation = ({ pathname }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const logout = useStore((state) => state.logout);
    const navigate = useNavigate();

    const handleLogOut = () => {
        logout();
        navigate("/loggedout");
    };

    // Close menu when escape key is pressed
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setIsOpen(false);
        }
    };

    // Close menu when clicking outside the menu
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    // Close menu when tabbing outside the menu
    const handleTabOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Add event listeners when menu is open
        if (isOpen) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("focusin", handleTabOutside);
        } else {
            // Remove event listeners when menu is closed
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleTabOutside);
        }
        // Clean up event listeners on unmount
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleTabOutside);
        };
    }, [isOpen]);

    return (
        <div ref={menuRef} className="mobile-nav-container">
            <IconButton
                aria-expanded={isOpen}
                aria-label="Navigation Menu"
                aria-controls="navigationMenu"
                icon={isOpen ? "Close" : "Menu"}
                developmentUrl="/assets/able-sprites.svg"
                events={{
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                }}
            />
            <ol id="navigationMenu" className={`dropdown ${isOpen && "dropdown-visible"}`}>
                <li>
                    <Link
                        className={`nav-item ${pathname === "/services" ? `nav-item-selected` : `nav-item-unselected`}`}
                        to="/services"
                    >
                        Services
                    </Link>
                </li>
                <li>
                    <Link
                        className={`nav-item ${
                            pathname === "/availability" ? `nav-item-selected` : `nav-item-unselected`
                        }`}
                        to="/availability"
                    >
                        Availability
                    </Link>
                </li>
                <li>
                    <a
                        className="nav-item nav-item-unselected"
                        href="mailto:lanesproductsupport@team.telstra.com?Subject=Portal%20Feedback"
                    >
                        Feedback
                    </a>
                </li>
                <li>
                    <Link
                        className={`nav-item ${
                            pathname === "/help-and-support" ? `nav-item-selected` : `nav-item-unselected`
                        }`}
                        to="/help-and-support"
                    >
                        Contact
                    </Link>
                </li>
                <li className="nav-item nav-item-unselected">
                    <button type="button" className="button-reset" onClick={handleLogOut}>
                        Sign out
                    </button>
                </li>
            </ol>
        </div>
    );
};

export default MobileNavigation;
