import { makeCaimanLogout } from "../../web-services/auth-service";
import { login as lanesLogin } from "../../web-services/login-service";
import { lanesLogout } from "../../web-services/logout-service";

export const createLoginSlice = (set, get) => ({
    loggedIn: false,
    loginError: null,
    login: async (code, nonce, options) => {
        try {
            const { token, refreshToken } = await lanesLogin({ code, nonce }, options);

            get().addTokensToStorage(token, refreshToken);

            set((state) => ({
                ...state,
                loggedIn: true,
                loginError: null,
            }));
        } catch (e) {
            // Error in login slice
            if (e.code === "ERR_CANCELED") {
                // Request cancelled due to react re-render
                throw e;
            } else if(e.code === "ERR_BAD_REQUEST"){
                return makeCaimanLogout("BAD_REQUEST");
            }else {
                // An Error has occurred requesting from tdn-service-identity
                set((state) => ({ ...state, loginError: e }));
                throw e;
            }
        } finally {
            // Removing codes
            sessionStorage.removeItem("nonce");
            sessionStorage.removeItem("state");
            sessionStorage.removeItem("return_path");
        }
    },
    logout: async () => {
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("refresh_token");
        try {
            const refreshToken = get().refreshToken;
            await lanesLogout(refreshToken);
            await makeCaimanLogout();
        } catch (e) {
            // Error occurred while logging out
        } finally {
            set((state) => ({
                ...state,
                initialized: false,
                loggedIn: false,
                loginError: null,
                idToken: null,
                refreshToken: null,
            }));
        }
    },
});
