import { useQueries } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { fetchServicesOnlineData, fetchInventoryCountData } from "../../../apis/subscriptions-apis";
import SummaryCard from "./SummaryCard/SummaryCard";

const ServiceSummaryCard = ({ tenancy }) => {
    const results = useQueries({
        queries: [
            {
                queryKey: ["ServiceSummary", tenancy],
                queryFn: async () => {
                    const data = await fetchServicesOnlineData(tenancy?.tenancyId);
                    return data;
                },
                retry: false,
            },
            {
                queryKey: ["InventoryCount", tenancy],
                queryFn: async () => {
                    const data = await fetchInventoryCountData(tenancy?.tenancyId);
                    return data;
                },
                retry: false,
            },
        ],
    });

    const isLoading = results.some((result) => result.isLoading);
    const tooltipContent = (
        <>
            This summary shows the total number of LANES services provisioned for the customer. Learn more about{" "}
            <Link to="/help-and-support#service-summary-info">service summary</Link>
        </>
    );
    const headers = ["Status", "Services"];
    const rows = [
        ["Online today", results[0].data?.online ?? 0],
        ["Online yesterday", results[0].data?.active ?? 0],
        ["Last seen 2+ days ago", results[0].data?.offline ?? 0],
    ];

    return (
        <SummaryCard
            showLoading={isLoading}
            title="Service summary"
            subtitle="All services"
            tooltipLabel="Service summary information"
            tooltipContent={tooltipContent}
            loadingText="Loading data..."
            totalCount={results[1].data?.numSubsTotal}
            totalCountTitle="Total services"
            actionButtonLabel="View all services"
            actionButtonAriaLabel="View all services service summary"
            actionButtonUrl="/services"
            data={{ headers, rows }}
        />
    );
};

export default ServiceSummaryCard;
