import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchSubscriptionById } from "../../apis/subscriptions-apis";
import { useParams } from "react-router-dom";
import { MessageInline } from "@able/react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/AbleBreadcrumbs";
import SubscriptionInformation from "./components/subscription-information-component";
import EnterpriseInfo from "./components/enterprise-info-component";
import LegalDisclaimer from "./components/legal-disclaimer-component";
import EnterpriseInfoSub from "./components/enterprise-info-sub-component";
import DownlinkThroughputChart from "./components/downlinkthroughput/downlink-throughput-chart-component";
import AverageMonthlyPerformance from "./components/MonthlyPerformanceCards/AverageMonthlyPerformance";
import AverageMonthlyImprovement from "./components/MonthlyPerformanceCards/AverageMonthlyImprovement";

import "./service-page-style.scss";

const ServicePage = () => {
    const { id } = useParams();
    const { data: subscriptionDetails, isLoading } = useQuery(["subscription", id], () => fetchSubscriptionById(id));

    useSetBreadcrumbs([
        { label: "Dashboard", to: "/" },
        { label: "Services", to: "/services" },
    ]);
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Service Page" });
    }, [])

    return (
        <>
            <Helmet>
                <title>Service Performance - Telstra LANES</title>
            </Helmet>

            {!isLoading && (
                <SubscriptionInformation subscriptionDetails={subscriptionDetails}></SubscriptionInformation>
            )}

            <MessageInline
                variant="Information"
                developmentUrl="/assets/able-sprites.svg"
                description="4G usage only, 5G usage coming soon"
                className="message-box"
            >
                <>
                    4G usage only, 5G usage coming soon <a href="#legal-disclaimer">*</a>
                </>
            </MessageInline>
            {!isLoading && (
                <>
                    <div className="performance-cards-container">
                        <AverageMonthlyImprovement subscriptionId={subscriptionDetails.subscriptionId} />
                        <AverageMonthlyPerformance subscriptionId={subscriptionDetails.subscriptionId} />
                    </div>
                </>
            )}

            <h2 className="info">Downlink throughput</h2>

            {!isLoading && (
                <>
                    {/* <ChartInformation subscriptionDetails={subscriptionDetails} /> */}
                    <DownlinkThroughputChart subscriptionId={subscriptionDetails.subscriptionId} />
                    <EnterpriseInfo />
                </>
            )}

            {!isLoading && (
                <>
                    <EnterpriseInfoSub />
                    <LegalDisclaimer />
                </>
            )}
        </>
    );
};

export default ServicePage;
