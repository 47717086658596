import axios from "axios";
import { getProperty } from "./config-util";

const apiGatewayUrl = getProperty("API_GATEWAY_URL", "https://noa-gateway-amqp-dev.apps.np.sdppcf.com/api/lanes");

const getAxiosRequestOptions = (options = {}) => {
    let token = sessionStorage.getItem("id_token");

    const headers = {
        authorization: "Bearer " + token,
    };
    const baseOptions = {
        method: options.method || "GET",
        url: options.url || `${apiGatewayUrl}${options.uri}`,
        headers: headers,
        ...options,
    };

    return baseOptions;
};

export const makeApiCall = async (options = {}, refreshAttemptsLeft = 1) => {
    const reqOptions = getAxiosRequestOptions(options);
    try {
        const response = await axios(reqOptions);

        return response.data?.data;
    } catch (err) {
        if (err.code === 404) return err.response.data?.data;
        throw err;
    }
};
