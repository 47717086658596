import { useState, useEffect } from "react";
import { Grid, Column } from "@able/react";
import FilterDropDownMenu from "./filter-dropdown-menu-component";
import dayjs from "../../../../utils/dayjs-util";

import useFetchSubscriptionThroughputHistoryQuery from "../../../../queries/subscription-throughput-queries";
import { formatNetworkThroughputData, formatSubscriptionThroughPutData } from "../../../../utils/throughput-data-util";
import ThroughputChartNoResults from "../throughput-chart-no-results-component";
import ThroughputValues from "./throughput-values-component";
import HighChartsWrapper from "./highchart-wrapper-component";
import "./filter-dropdown-menu-component-style.scss";

function DownlinkThroughputChart({ subscriptionId }) {
    const [displayLastDays, setDisplayLastDays] = useState(true);
    const [displayCurrentDay, setDisplayCurrentDay] = useState(false);
    const [period, setPeriod] = useState("daily");
    const [data, setData] = useState({});
    const [subscriptionThroughputData, setsubscriptionThroughputData] = useState([]);
    const currentDate = dayjs().tz("Australia/Melbourne");
    let dateTimeFrom;
    let dateTimeTo;
    const periodVar = displayLastDays ? "daily" : "hourly";

    if (displayLastDays) {
        dateTimeFrom = currentDate.subtract(30, "day").startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
    } else if (displayCurrentDay) {
        dateTimeFrom = currentDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
    }

    const { networkData, subscriptionData, isLoading, isSuccess } = useFetchSubscriptionThroughputHistoryQuery(
        subscriptionId,
        periodVar,
        dateTimeFrom,
        dateTimeTo
    );

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setsubscriptionThroughputData(subscriptionData);
            const formattedData = formatSubscriptionThroughPutData(subscriptionData, networkData, periodVar);
            const formattedNetworkData = formatNetworkThroughputData(networkData, periodVar);
            setData({
                filteredData: formattedData,
                networkThroughPut: formattedNetworkData,
                barKey: "downLink",
                period: periodVar,
            });
        }
    }, [subscriptionData, networkData, isLoading, isSuccess, periodVar]);

    return (
        <>
            <Grid className="throughput-content-detail">
                <Column vxs={12} bsm={12} bmd={12} blg={12}>
                    <FilterDropDownMenu
                        setDisplayLastDays={setDisplayLastDays}
                        setDisplayCurrentDay={setDisplayCurrentDay}
                        setPeriod={setPeriod}
                    />
                </Column>
                {!isLoading && isSuccess && (
                    <>
                        <Column vxs={12} bsm={3} bmd={3} blg={3}>
                            <div className="left-content">
                                <ThroughputValues
                                    subscriptionThroughput={subscriptionThroughputData?.series}
                                    period={period}
                                ></ThroughputValues>
                            </div>
                        </Column>
                        <Column vxs={12} bsm={9} bmd={9} blg={9}>
                            <HighChartsWrapper
                                subscriptionId={subscriptionId}
                                subscriptionData={subscriptionData}
                                formattedSubscriptionThroughputData={data.filteredData}
                                formattedNetworkThroughputData={data.networkThroughPut}
                                period={period}
                                setPeriod={setPeriod}
                                dateTimeFrom={dateTimeFrom}
                                dateTimeTo={dateTimeTo}
                                setsubscriptionThroughputData={setsubscriptionThroughputData}
                                selectedOption={displayLastDays ? "displaylastdays" : "today"}
                            />
                        </Column>
                    </>
                )}
                {!isLoading && !isSuccess && <ThroughputChartNoResults />}
                {isLoading && !isSuccess && (
                    <Column vxs={12} bsm={12} bmd={12} blg={12}>
                        <div className="loading loader-align-center">
                            Loading {displayCurrentDay ? "current day" : "last 30 days"} performance data
                        </div>
                    </Column>
                )}
            </Grid>
        </>
    );
}

export default DownlinkThroughputChart;
