import { Column, Grid } from "@able/react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/AbleBreadcrumbs";
import { PageHeading } from "../../common/components/page-heading/page-heading";
import useStore from "../../store/app-store";
import { filterInventory } from "./components/filter-inventory";
import SearchCombobox from "./components/SearchCombobox";
import ServicesTable from "./components/ServicesTable/ServicesTable";
import WelcomeDetails from "../dashboard/components/welcome-details-component";
import "./services-page-style.scss";

const useSubscriptionData = () => {
    const tenancyData = useStore((state) => state.tenancyData);
    const fetchTenancy = useStore((state) => state.fetchTenancy);
    const fetchSubscriptions = useStore((state) => state.fetchSubscriptions);
    const subscriptions = useStore((state) => state.subscriptions);
    const [inventory, setInventory] = useState({ matches: [], fieldValue: "", filteredInventory: subscriptions });

    // You should consider collapsing this into a single useEffect
    useEffect(() => {
        const controller = new AbortController();

        if (tenancyData === null) {
            fetchTenancy({ signal: controller.signal }).catch((e) => console.error(e));
        }
        if (tenancyData) {
            fetchSubscriptions({ signal: controller.signal }).catch((e) => console.error(e));
        }

        return () => controller.abort();
    }, [tenancyData, fetchSubscriptions, fetchTenancy]);

    useEffect(() => {
        if (subscriptions) {
            setInventory((s) => ({ ...s, filteredInventory: subscriptions }));
        }
    }, [subscriptions]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Services Page" });
    }, [])

    return { inventory, setInventory, subscriptions, tenancyData };
};

const ServicesPage = () => {
    const { inventory, setInventory, subscriptions, tenancyData } = useSubscriptionData();
    useSetBreadcrumbs([{ label: "Dashboard", to: "/" }]);

    const onFieldChange = (fieldValue) => {
        const { filteredInventory, matches } = filterInventory(subscriptions, fieldValue);
        setInventory({ matches, fieldValue, filteredInventory });
    };

    return (
        <>
            <Helmet>
                <title>Services - Telstra LANES</title>
            </Helmet>
            <Grid>
                <Column cols={12}>
                    <PageHeading content="Services" />
                    <WelcomeDetails tenancyName={tenancyData?.tenancyName} />
                    {inventory.filteredInventory ? (
                        <div>
                            <SearchCombobox
                                helperText="Results will be filtered as you type."
                                fieldValue={inventory.fieldValue}
                                matches={inventory.matches}
                                onFieldChange={onFieldChange}
                            />
                            <ServicesTable
                                columns={["Service number", "IMSI", "SIM serial", "IMEI", "Device type", "Device"]}
                                data={inventory.filteredInventory}
                                externalFilters={[inventory.filterField]}
                            />
                        </div>
                    ) : (
                        <p className="loading">Fetching Subscription Data</p>
                    )}
                </Column>
            </Grid>
        </>
    );
};

export default ServicesPage;
