import { Grid, SkipLink } from "@able/react";
import AbleBreadcrumbs from "./breadcrumbs/AbleBreadcrumbs";
import Navigation from "./navigation/Navigation";

const AppLayout = ({ children }) => {
    return (
        <>
            <SkipLink contentId="#content" />
            <Navigation />
            <AbleBreadcrumbs />
            <Grid>
                <main>{children}</main>
            </Grid>
        </>
    );
};

export default AppLayout;
