import "./welcome-details-component-style.scss";

const WelcomeDetails = (props) => {

    return (
        <div>
            {
                props.tenancyName ? <span className="tenancy-name">{props.tenancyName}</span> :
                    <p className="animated-background"></p>
            }
        </div>
    )
}

export default WelcomeDetails;