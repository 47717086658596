import { ActionButton } from "@able/react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchAvailabilityData } from "../../../apis/availability-apis";
import InfoTooltip from "../../../common/components/info-tooltip/index";
import "./availability-card-component-style.scss";

const NetworkAvailabilityCard = () => {
    const navigate = useNavigate();

    const availabilityQuery = useQuery(["network-availability"], () => fetchAvailabilityData(), {
        refetchOnWindowFocus: false,
    });

    return (
        <div className="card">
            <div className="availability-content">
                <div className="card-header">
                    <h2 className="header-txt">Network availability</h2>
                    <InfoTooltip id="network-availability-tooltip" label="Network Availability Information">
                        This is the monthly availability of our public 4G network. Learn more about{" "}
                        <Link to="/help-and-support#network-availability-info">network availabiltiy</Link>
                    </InfoTooltip>
                </div>
                <div className="availability-details">
                    <span className="availability">
                        {availabilityQuery &&
                            availabilityQuery.data &&
                            availabilityQuery.data[0] &&
                            availabilityQuery.data[0].availability}
                        %
                    </span>
                    <span className="average">National average (last 30 days)</span>
                    <span className="average">
                        Target{" "}
                        {availabilityQuery &&
                            availabilityQuery.data &&
                            availabilityQuery.data[0] &&
                            availabilityQuery.data[0].availabilityTarget}
                        %
                    </span>
                </div>
                <ActionButton
                    label="View network availability"
                    variant="LowEmphasis"
                    element="button"
                    developmentUrl="./assets/able-sprites.svg"
                    events={{ onClick: () => navigate("/availability") }}
                    className="availability-btn"
                />
            </div>
        </div>
    );
};

export default NetworkAvailabilityCard;
