import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useOnRouteChange = (callback) => {
    const pathRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        // On initial UE run pathRef will be null
        if (pathRef.current !== null && pathRef.current !== pathname) {
            callback();
        }

        if (pathRef.current === null) {
            pathRef.current = pathname;
        }
    }, [pathname, callback]);
};
