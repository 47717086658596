import { checkTokens } from "../../utils/token-util";
import { callAuthService } from "../../web-services/auth-service";
export const createInitializeSlice = (set, get) => ({
    initialized: false,
    returnPath: null,
    initializeApp: async (options) => {
        // Get query params from url and check state and code in case of redirecting from CAIMEN
        const queryParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const { state, code } = queryParams;

        const stateCode = sessionStorage.getItem("state");
        const nonceCode = sessionStorage.getItem("nonce");

        // If the codes exist and they match then login via service-identity.
        if (state && stateCode && state === stateCode) {
            // State and StateCode detected.
            try {
                // Login via Identity Microservice
                await get().login(code, nonceCode, options);
                set((state) => ({ ...state, initialized: true }));
            } catch (err) {
                if (err.code === "ERR_CANCELED") {
                    // Request cancelled due to react re-render
                    // TODO: add better error handling to manage this situation
                } else {
                    set((state) => ({ ...state, initialized: true }));
                }
            }

            sessionStorage.removeItem("return_path");

            // Break out of here
            return;
        }
        // Make sure they're gone!
        sessionStorage.removeItem("nonce");
        sessionStorage.removeItem("state");

        /**
         * Evaluate tokens:
         * If we're not being redirected to from Caimen we should also check if tokens exist.
         * If they are valid let the user login.
         */
        // Checking tokens
        const validTokens = checkTokens();

        if (validTokens === false) {
            sessionStorage.removeItem("id_token");
            sessionStorage.removeItem("refresh_token");

            // Set the path you want to come back to here.
            sessionStorage.setItem("return_path", window.location.pathname);

            set((state) => ({ ...state, initialized: true }));
            return callAuthService();
        }

        // Tokens are valid, store them in state.
        if (validTokens === true) {
            // Update the token information in state.
            get().setTokens();
            set((state) => ({ ...state, initialized: true, loggedIn: true }));
            sessionStorage.removeItem("return_path");
        }
    },
});
