import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Grid, Column } from "@able/react";
import { useQuery } from "@tanstack/react-query";
import { fetchAvailabilityHistoryData } from "../../apis/availability-apis";
import { Helmet } from "react-helmet";
import { PageHeading } from "../../common/components/page-heading/page-heading";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/AbleBreadcrumbs";
import { ArrowUpIcon, ArrowDownIcon } from "../../common/components/MaxMinArrows";
import dayjs from "../../utils/dayjs-util";
import AvailabilityChart from "./components/availability-chart-component";

import "./availability-page-style.scss";

const AvailabilityPage = () => {
    const dateTimeFrom = dayjs().subtract(12, "month").format("YYYY-MM-DD");
    const dateTimeTo = dayjs().format("YYYY-MM-DD");
    let maxAvailability = "";
    let minAvailability = "";
    let lastUpdatedOn = "";

    const availabilityHistoryQuery = useQuery(
        ["network-history-availability"],
        () =>
            fetchAvailabilityHistoryData({
                params: {
                    dateTimeFrom: dateTimeFrom,
                    dateTimeTo: dateTimeTo,
                },
            }),
        { refetchOnWindowFocus: false }
    );

    if (availabilityHistoryQuery && availabilityHistoryQuery.data && availabilityHistoryQuery.data.length > 0) {
        const availabilityList = availabilityHistoryQuery.data.map((av) => av.availability);
        maxAvailability = Math.max(...availabilityList);
        minAvailability = Math.min(...availabilityList);
        lastUpdatedOn = dayjs(availabilityHistoryQuery.data[0].timestamp).format("DD MMMM YYYY");
    }

    useSetBreadcrumbs([{ label: "Dashboard", to: "/" }]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Availability Page"});
    }, [])

    return (
        <>
            <Helmet>
                <title>Availability - Telstra LANES</title>
            </Helmet>
            <PageHeading content="Availability" />
            <h2 className="info">Public LTE network coverage availability (last 12 months)</h2>
            <div className="last-updated-info">
                <span>Last updated {lastUpdatedOn}</span>
            </div>
            <Grid className="availability-content-detail">
                <Column vxs={12} bsm={3} bmd={3} blg={3}>
                    <div className="left-content">
                        <dl aria-label="Service information, availability values list.">
                            <dt className="highest-availability">
                                <ArrowUpIcon developmentUrl="/assets/able-sprites.svg" />
                                <span className="availability-value">{maxAvailability}%</span>
                            </dt>
                            <dd className="period-info">
                                <span>highest availability in a 12 month period</span>
                            </dd>

                            <dt className="lowest-availability">
                                <ArrowDownIcon developmentUrl="/assets/able-sprites.svg" />
                                <span className="availability-value">{minAvailability}%</span>
                            </dt>
                            <dd className="period-info">
                                <span>lowest availability in a 12 month period </span>
                            </dd>
                        </dl>
                    </div>
                </Column>
                <Column vxs={12} bsm={9} bmd={9} blg={9}>
                    <AvailabilityChart data={availabilityHistoryQuery?.data} />
                </Column>
            </Grid>
        </>
    );
};

export default AvailabilityPage;
