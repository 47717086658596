import { ActionButton } from "@able/react";
import useStore from "../../store/app-store";
import { Navigate, useNavigate } from "react-router-dom";
import "./login-page-style.scss"
import { Helmet } from "react-helmet";

const LoginPage = () => {
    const loggedIn = useStore((state) => state.loggedIn);
    const navigate = useNavigate();

    if (loggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Helmet>
                <title>Sign in to Telstra with your Telstra ID</title>
            </Helmet>
            <div className="container">
                <div className="content-box">
                    <p>Login via OIDC provider</p>
                    <ActionButton
                        label="Login"
                        element="button"
                        variant="HighEmphasis"
                        developmentUrl=""
                        events={{
                            onClick: () => navigate("/"),
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default LoginPage;
