import { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import accessibility from "highcharts/modules/accessibility";
import dayjs from "../../../../utils/dayjs-util";
import { getFrequencyInterval } from "../../../../utils/frequency-util";
import { getTimeFormat } from "../../../../utils/date-util";
import { getAESTEndTime, getAESTStartTime } from "../../../../utils/date-util";
import {
    fetchSubscriptionThroughputHistoryData,
    fetchNetworkThroughputHistoryData,
} from "../../../../apis/subscriptions-apis";
import { formatNetworkThroughputData, formatSubscriptionThroughPutData } from "../../../../utils/throughput-data-util";

drilldown(Highcharts);
accessibility(Highcharts);

const HighChartsWrapper = ({
    subscriptionId,
    formattedSubscriptionThroughputData = [],
    formattedNetworkThroughputData = [],
    period,
    setPeriod,
    dateTimeFrom,
    dateTimeTo,
    selectedOption,
    setsubscriptionThroughputData,
    subscriptionData,
    chartRef,
}) => {
    let periodVar; //Declared local variable to update period when it is drill down
    // Checking formattedSubscriptionThroughputData and formattedNetworkThroughputData, if data doesn't exist do not load chart
    // Also checking to render only if period is daily and selected option is last 30 days (or)
    // selected option = today, because this should not render if selected option is last 30 days and period = hourly
    const options =
        (period === "daily" && selectedOption === "displaylastdays") || selectedOption === "today"
            ? {
                  chart: {
                      type: "column",
                      events: {
                          drilldown: async function (e) {
                              if (!e.seriesOptions) {
                                  let chart = this;
                                  const aestTimeFromHourly = getAESTStartTime(e.point.x);
                                  const aestTimeToHourly = getAESTEndTime(e.point.x);
                                  const dateTimeFromHourly = aestTimeFromHourly.utc().format("YYYY-MM-DDTHH:mm:ss");
                                  const dateTimeToHourly = aestTimeToHourly.utc().format("YYYY-MM-DDTHH:mm:ss");
                                  periodVar = "hourly";
                                  setPeriod("hourly");
                                  const networkThroughput = await fetchNetworkThroughputHistoryData(subscriptionId, {
                                      params: {
                                          period: "hourly",
                                          dateTimeFrom: dateTimeFromHourly,
                                          dateTimeTo: dateTimeToHourly,
                                      },
                                  });

                                  const subThroughput = await fetchSubscriptionThroughputHistoryData(subscriptionId, {
                                      params: {
                                          period: "hourly",
                                          dateTimeFrom: dateTimeFromHourly,
                                          dateTimeTo: dateTimeToHourly,
                                      },
                                  });

                                  if (subThroughput && networkThroughput) {
                                      setsubscriptionThroughputData(subThroughput);
                                      const subsThroughputData = formatSubscriptionThroughPutData(
                                          subThroughput,
                                          networkThroughput,
                                          "hourly"
                                      );
                                      const subsDataLength = subsThroughputData.values.length;
                                      const networkDataLength = subsThroughputData.values.length;
                                      const networkThroughputData = formatNetworkThroughputData(
                                          networkThroughput,
                                          "hourly"
                                      );

                                      chart.addSingleSeriesAsDrilldown(e.point, {
                                          name: "Downlink throughput",
                                          data: subsThroughputData.values,
                                          color: "#0064d2",
                                          stack: "DownLink",
                                          groupPadding: subsDataLength >= 10 ? 0.1 : 0,
                                          pointPadding: subsDataLength >= 10 ? 0.1 : 0,
                                          maxPointWidth: 20,
                                          borderWidth: 0,
                                          shadow: false,
                                      });
                                      chart.addSingleSeriesAsDrilldown(e.point, {
                                          name: "Downlink Network",
                                          data: networkThroughputData.values,
                                          color: "#AAAAAA",
                                          stack: "DownLink",
                                          groupPadding: networkDataLength >= 10 ? 0.1 : 0,
                                          pointPadding: networkDataLength >= 10 ? 0.1 : 0,
                                          borderWidth: 0,
                                          shadow: false,
                                          maxPointWidth: 20,
                                      });
                                      chart.applyDrilldown();
                                      const chartFreqInterval = getFrequencyInterval("Hourly") * 3;
                                      const chartMin =
                                          aestTimeFromHourly.utc().valueOf() / 1000 - getFrequencyInterval("Hourly");
                                      const chartMax = aestTimeToHourly.utc().valueOf() / 1000;
                                      chart.xAxis[0].update({
                                          tickInterval: chartFreqInterval,
                                          min: chartMin,
                                          max: chartMax,
                                      });
                                  }
                              }
                          },
                          render: function () {
                              const chart = this;

                              if (
                                  (period === "daily" &&
                                      periodVar !== "hourly" &&
                                      selectedOption === "displaylastdays") ||
                                  selectedOption === "today"
                              ) {
                                  if (chart.breadcrumbs) {
                                      chart.breadcrumbs.destroy();
                                  }
                              }
                          },
                      },
                  },
                  title: {
                      text: "",
                  },
                  subtitle: {
                      text: "",
                  },
                  legend: {
                      itemDistance: 60,
                      align: "center",
                      verticalAlign: "bottom",
                      x: 0,
                      y: 0,
                      labelFormatter: function () {
                          return (
                              '<div class="legend-item"><span>' +
                              (this.name.toLowerCase() === "downlink throughput"
                                  ? "Avg speed improvement with LANES"
                                  : this.name.toLowerCase() === "downlink network"
                                  ? "Avg speed without LANES"
                                  : "") +
                              "</span></div>"
                          );
                      },
                  },
                  tooltip: {
                      formatter: function () {
                          var tooltip =
                              '<table><tr><th colspan="2" style="color: rgb(65, 65, 65);"><b>Average speed</b></th></tr>';
                          this.points.forEach((point, index, array) => {
                              tooltip +=
                                  '<tr><td width="40%"><hr style="padding:0; margin:0; white-space:nowrap; background-color: ' +
                                  point.series.color +
                                  ";color: " +
                                  point.series.color +
                                  '; height: 3px;width:100%; float: left;border: none;" /></td> <td style="text-align: right; white-space:nowrap;">' +
                                  (point.series.name.toLowerCase() === "downlink throughput" ? point.total : point.y) +
                                  'Mbps</td><td style="text-align: left; padding:0; margin:0; white-space:nowrap;">' +
                                  (point.series.name.toLowerCase() === "downlink throughput"
                                      ? "with LANES"
                                      : point.series.name.toLowerCase() === "downlink network"
                                      ? "without LANES"
                                      : "") +
                                  "</td></tr>";
                          });
                          tooltip +=
                              '<tr><td colspan="2">' +
                              ((periodVar && periodVar.toLowerCase() === "hourly") || period === "hourly"
                                  ? getTimeFormat(this.x, periodVar !== undefined ? periodVar : period, "datetime")
                                  : getTimeFormat(
                                        this.x,
                                        periodVar !== undefined ? periodVar : period,
                                        "daily",
                                        true
                                    )) +
                              "</td></tr></table>";
                          return tooltip;
                      },
                      shared: true,
                      crosshairs: false,
                      borderColor: "rgb(250, 250, 250)",
                      shdow: true,
                      backgroundColor: "rgb(255, 255, 255)",
                      useHTML: true,
                      borderWidth: 1,
                      style: {
                          color: "rgb(119, 119, 119)",
                      },
                  },
                  credits: {
                      enabled: false,
                  },
                  yAxis: {
                      title: {
                          text: "",
                      },
                      labels: {
                          formatter: function () {
                              return `${this.value}Mbps`;
                          },
                      },
                      lineWidth: 2,
                      lineColor: "#8F8F8F",
                      gridLineWidth: 1,
                      gridLineColor: "#8F8F8F",
                      gridLineDashStyle: "Dot",
                  },
                  xAxis: {
                      min:
                          periodVar && periodVar.toLowerCase() === "daily"
                              ? dayjs(dateTimeFrom).valueOf() / 1000 - getFrequencyInterval(period)
                              : selectedOption === "today"
                              ? dayjs().tz("Australia/Melbourne").startOf("day").utc().valueOf() / 1000 -
                                getFrequencyInterval("Hourly")
                              : dayjs(dateTimeFrom).startOf("day").valueOf() / 1000 -
                                getFrequencyInterval(periodVar !== undefined ? periodVar : period),
                      max:
                          periodVar && periodVar.toLowerCase() === "daily"
                              ? dayjs(dateTimeTo).valueOf() / 1000 + getFrequencyInterval(period)
                              : selectedOption === "today"
                              ? dayjs().tz("Australia/Melbourne").endOf("day").utc().valueOf() / 1000
                              : dayjs(dateTimeTo).endOf("day").valueOf() / 1000 +
                                getFrequencyInterval(periodVar !== undefined ? periodVar : period),
                      tickInterval: getFrequencyInterval(period) * 3,
                      style: {
                          fontSize: "14px",
                      },
                      labels: {
                          rotation: 0,
                          formatter: function (tick) {
                              const formattedTick = getTimeFormat(
                                  tick.value,
                                  periodVar !== undefined ? periodVar : period
                              );
                              return formattedTick;
                          },
                          style: {
                              fontSize: "12px",
                          },
                      },
                      lineColor: "#8F8F8F",
                      lineWidth: 2,
                  },
                  plotOptions: {
                      column: {
                          stacking: "normal",
                      },
                      series: {
                          pointPadding: 0.1,
                          groupPadding: 0,
                          borderWidth: 0,
                          shadow: false,
                      },
                  },
                  series: [
                      {
                          name: "Downlink throughput",
                          data: formattedSubscriptionThroughputData.values,
                          color: "#0064d2",
                          stack: "DownLink",
                          maxPointWidth: 20,
                      },
                      {
                          name: "Downlink Network",
                          data: formattedNetworkThroughputData.values,
                          color: "#AAAAAA",
                          stack: "DownLink",
                          maxPointWidth: 20,
                      },
                  ],
                  drilldown: {
                      activeAxisLabelStyle: {
                          cursor: "default",
                          fontWeight: "normal",
                          textDecoration: "none",
                      },
                      breadcrumbs: {
                          floating: false,
                          formatter: function (e) {
                              if (
                                  ((periodVar && periodVar.toLowerCase() === "hourly") || period === "hourly") &&
                                  selectedOption !== "today"
                              ) {
                                  if (e.level === 0) {
                                      return "Daily Performance";
                                  } else {
                                      return `Hourly ${e.levelOptions.name}`;
                                  }
                              } else {
                                  return;
                              }
                          },
                          events: {
                              click: function (button, breadcrumbs, e) {
                                  setPeriod("daily");
                                  setsubscriptionThroughputData(subscriptionData);
                              },
                          },
                      },
                      series: [],
                  },
              }
            : {};

    /** Due to accessibility requirements they have requested to reduce the verbosity
     * Accessing the DOM and modifying it like this is bad practice, but I have no alternative as
     * I do not have access to the virtual dom and can't find an API reference.
     */
    useEffect(() => {
        const highchartsRoot = document.getElementsByClassName("highcharts-root");
        if (highchartsRoot.length) {
            for (let element of highchartsRoot) {
                Array.from(element.querySelectorAll("desc"))
                    .find((el) => el.textContent === "Created with Highcharts 10.3.3")
                    ?.remove();
            }
        }
    }, []);

    return <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />;
};

export default HighChartsWrapper;
