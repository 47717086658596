import { useEffect, useRef } from "react";
import { ActionButton } from "@able/react";
import InfoTooltip from "../../../../common/components/info-tooltip";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./SummaryCard.scss";

const SummaryCard = ({
    showLoading = false,
    title,
    subtitle,
    tooltipLabel,
    tooltipContent = null,
    loadingText = "Loading data...",
    totalCount,
    totalCountTitle,
    actionButtonLabel,
    actionButtonAriaLabel,
    actionButtonUrl,
    showPercent = false,
    data = { headers: [], rows: [] },
}) => {
    const ref = useRef(null);
    const navigate = useNavigate();
    const infoToolTipId = `${title.trim().replace(/\s+/g, "-").toLowerCase()}-tooltip`;

    useEffect(() => {
        // Set the aria-label if one is provided and if it's not set before
        if (ref.current && !ref.current.ariaLabel && actionButtonAriaLabel) {
            ref.current.ariaLabel = actionButtonAriaLabel;
        }
    });

    return (
        <div className="summary-card">
            <div className="summary-card-wrapper">
                <div className="summary-card-container">
                    <div className="summary-card-header">
                        <h2 className="summary-card-title">{title}</h2>
                        {tooltipContent && (
                            <InfoTooltip id={infoToolTipId} label={tooltipLabel}>
                                {tooltipContent}
                            </InfoTooltip>
                        )}
                    </div>
                    {subtitle && (
                        <div className="summary-card-subtitle">
                            <h3 className="summary-card-subtitle-wrapper">{subtitle}</h3>
                        </div>
                    )}
                    {showLoading ? (
                        <div className="summary-card-loader">{loadingText}</div>
                    ) : (
                        <>
                            <div className="summary-card-total-count">
                                <span className="service-count-txt">
                                    {totalCount} {showPercent ? "%" : ""}
                                </span>
                            </div>
                            <div className="summary-card-total-count-title">
                                <span className="summary-card-total-count-title-wrapper">{totalCountTitle}</span>
                            </div>
                            <table className="summary-card-summary-table">
                                <thead>
                                    <tr>
                                        {data.headers.map((header, key) => (
                                            <th key={key}>{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.rows.map((row, key) => (
                                        <tr key={key}>
                                            {row.map((col, key) => (
                                                <td key={key}>{col}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
                {actionButtonLabel && actionButtonUrl && (
                    <ActionButton
                        label={actionButtonLabel}
                        variant="LowEmphasis"
                        element="button"
                        developmentUrl="./assets/able-sprites.svg"
                        events={{ onClick: () => navigate(actionButtonUrl) }}
                        className="action-btn"
                        elementRef={ref}
                    />
                )}
            </div>
        </div>
    );
};

SummaryCard.propTypes = {
    showLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    tooltipLabel: PropTypes.string,
    tooltipContent: PropTypes.element,
    loadingText: PropTypes.string,
    totalCount: PropTypes.number,
    showPercent: PropTypes.bool,
    totalCountTitle: PropTypes.string,
    actionButtonLabel: PropTypes.string,
    actionButtonAriaLabel: PropTypes.string,
    actionButtonUrl: PropTypes.string,
    data: PropTypes.object,
};

export default SummaryCard;
