import { makeApiCall } from "../utils/request-util";

export const fetchInventoryCountData = async (tenancyId, opts) => {
    let options = {
        uri: "/inventory/v1/subscriptions/count",
        params: { tenancyId },
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchSubscriptionsData = async (tenancyId, opts) => {
    let options = {
        method: "GET",
        uri: "/inventory/v1/subscriptions",
        params: { tenancyId: tenancyId },
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchSubscriptionById = async (subscriptionId, opts) => {
    let options = {
        method: "GET",
        uri: `/inventory/v1/subscriptions/${subscriptionId}`,
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchSubscriptionThroughputHistoryData = async (subscriptionId, opts) => {

    let options = {
        uri: `/observability/v1/subscriptions/${subscriptionId}/history`,
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchNetworkThroughputHistoryData = async (subscriptionId, opts) => {

    let options = {
        uri: `/observability/v1/network/${subscriptionId}/history`,
        ...opts
    }

    return await makeApiCall(options)
}


export const fetchServicesOnlineData = async (tenancyId, opts) => {
    let options = {
        uri: "/inventory/v1/subscriptions/status",
        params: { tenancyId },
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchServiceImprovementData = async (tenancyId, opts) => {

    let options = {
        uri: `/observability/v1/tenancies/${tenancyId}/networkDeviation`,
        ...opts
    }

    return await makeApiCall(options)
}

export const fetchSubscriptionActiveStatusById = async (subscriptionId, opts) => {
    let options = {
        method: "GET",
        uri: `/inventory/v1/subscriptions/${subscriptionId}/connection-status`,
        ...opts
    }

    return await makeApiCall(options)
}
