import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { formatAvailabilityList } from "../../../utils/availability-util";
require("highcharts/modules/accessibility")(Highcharts);

const AvailabilityChart = (props) => {
    if (props && props.data) {
        return (
            <HighchartsReact highcharts={Highcharts} options={getChartOptions(props.data)}></HighchartsReact>
        )
    }
    return (
        <></>
    )

}

function getChartOptions(dataList) {
    const result = formatAvailabilityList(dataList);
    setTimeout(function () {
        const highchartsRoot = document.getElementsByClassName("highcharts-root");
        if (highchartsRoot.length) {
            for (let element of highchartsRoot) {
                Array.from(element.querySelectorAll("desc"))
                    .find((el) => el.textContent === "Created with Highcharts 10.3.2")
                    ?.remove();
            }
        }

    }, 1000);
    return {
        chart: {
            type: 'line'
        },
        credits: {
            enabled: false,
        },

        title: {
            text: ''
        },
        subtitle: {
            text: ""
        },
        accessibility: {
            enabled: true,
            keyboardNavigation: {
                enabled: true,
            },
        },
        xAxis: {
            tickLength: 0,
            categories: result.map(res => res.month),
            lineWidth: 2,
            showTicks: false,
            lineColor: 'transparent',
            // accessibility: {
            //     enabled: true,
            //     description: 'This is from xAxis',
            // },
        },
        yAxis: {
            min: 99.0,
            max: 100.0,
            tickInterval: 0.2,
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return this.value + '%';
                }
            },
            lineWidth: 2,
            gridLineWidth: 1,
            gridLineDashStyle: "longdash",
            offset: 0,
            lineColor: 'transparent',
            accessibility: {
                enabled: true,
                description: 'This is from y axis',
            },
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            borderColor: 'rgb(250, 250, 250)',
            shdow: true,
            backgroundColor: 'rgb(255, 255, 255)',
            useHTML: true,
            borderWidth: 1,
            style: {
                color: 'rgb(119, 119, 119)',
            },
        },
        plotOptions: {
            series: {
                pointWidth: 20,
                connectNulls: true
            },
            marker: {
                enabled: false
            },
        },
        series: [{
            color: "#0064d2",
            name: 'Availability',
            data: result.map(av => av.availability),
            marker: {
                enabled: false
            },
            events: {
                legendItemClick: function () {
                    return false;
                },
            },
            accessibility: {
                description: '',
                keyboardNavigation: {
                    enabled: true,
                    seriesNavigation: {
                        mode: 'serialize',
                        pointNavigationEnabledThreshold: false,
                        rememberPointFocus: false,
                        skipNullPoints: true,
                    },
                    wrapAround: true,
                },

            },
        },
        {
            dashStyle: 'dash',
            color: "#d93923",
            name: 'Availability Target',
            data: result.map(av => av.availabilityTarget),
            marker: {
                enabled: false
            },
            accessibility: {
                description: '',
                keyboardNavigation: {
                    enabled: true,
                    seriesNavigation: {
                        mode: 'serialize',
                        pointNavigationEnabledThreshold: false,
                        rememberPointFocus: false,
                        skipNullPoints: true,
                    },
                    wrapAround: true,
                },

            },
            events: {
                legendItemClick: function () {
                    return false;
                },
            },
        }]
    }
}

export default AvailabilityChart;