import { Breadcrumbs } from "@able/react";
import { useEffect } from "react";
import useStore from "../../../store/app-store";

export const useSetBreadcrumbs = (breadcrumbsArray) => {
    const setBreadcrumbs = useStore((state) => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs(breadcrumbsArray);
        // Adding the items to the dependency array causes issues rendering the Able breadcrumbs.
        // If you want a proper fix, go ask the Able team.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return;
};

const AbleBreadcrumbs = () => {
    const breadcrumbs = useStore((state) => state.breadcrumbs);

    if (breadcrumbs.length > 0) {
        return (
            <Breadcrumbs
                breadcrumbs={breadcrumbs}
                setBackgroundToWhite={true}
                developmentUrl="/assets/able-sprites.svg"
            />
        );
    }

    return null;
};

export default AbleBreadcrumbs;
