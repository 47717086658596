import React, { useId, useState } from "react";
import { Select } from "@able/react";
import "./filter-dropdown-menu-component-style.scss";

const FilterDropDownMenu = ({ setDisplayLastDays, setDisplayCurrentDay, setPeriod }) => {
    const options = [
        { value: "last30days", label: "Last 30 days" },
        { value: "today", label: "Today" },
    ];

    const [title, setTitle] = useState("last30days");
    const id = useId();

    const handleClickLastDays = () => {
        setTitle("last30days");
        setDisplayLastDays(true);
        setDisplayCurrentDay(false);
        setPeriod("daily");
    };

    const handleClickToday = () => {
        setTitle("Today");
        setDisplayCurrentDay(true);
        setDisplayLastDays(false);
        setPeriod("hourly");
    };

    const handleSelectChange = (event) => {
        const selectedOption = options.find((option) => option.value === event.target.value);
        setTitle(selectedOption.label);

        switch (event.target.value) {
            case "last30days":
                handleClickLastDays();
                break;
            case "today":
                handleClickToday();
                break;
            default:
                break;
        }
    };

    return (
        <Select
            id={id}
            label="Date range"
            helpText="Show data from a selected date range"
            options={options}
            onChange={handleSelectChange}
            className="dropdown-menu"
            disableFirstOption="true"
            defaultValue={title}
        />
    );
};

export default FilterDropDownMenu;
