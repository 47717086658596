export const ActiveSessionIcon = ({ developmentURL }) => {
    const spriteRef = developmentURL ? developmentURL + "#Tick" : "//www.telstra.com.au/content/dam/tcom/able/able-sprites.svg?v27#Tick";
    return (
        <svg className="able-icon" style={{ width: 24, height: 24 }} role="img" aria-hidden="true" focusable="false">
            <use href={spriteRef}
                stroke="var(--utilityPositive)"
                strokeWidth={4}
                style={{ fill: "var(--utilityPositive)", transform: "scale(0.5) translate(12px, 12px)" }}
                color="var(--utilityPositive)"
                fill="var(--utilityPositive)" />
            <circle strokeWidth={2} cx={12} cy={12} r={9} viewBox="0 0 24 24" stroke="var(--utilityPositive)" fill="transparent" />
        </svg>
    )
}
