import "./external-links-component-style.scss";
import { ActionButton, MessageInline } from "@able/react";

const ExternalLink = (props) => {
    const preLinkTxt = props.preLinkTxt || "";
    const linkTxt = props.linkTxt || "";
    const linkUrl = props.linkUrl || "";

    const generateInlineLinkTxt = () => {
        if (preLinkTxt !== "") {
            return (
                <MessageInline
                    variant="Default"
                    description={
                        <>
                            <span className="preLinktxt">{preLinkTxt}</span>
                            <ActionButton
                                label={linkTxt}
                                variant="LowEmphasis"
                                element="a"
                                href={linkUrl}
                                externalSite
                                developmentUrl="./assets/able-sprites.svg"
                                target="_blank"
                                className="inlineAnchor"
                            />
                        </>
                    }
                />
            );
        } else {
            return (
                <MessageInline
                    variant="Default"
                    className="linkWrapper"
                    description={
                        <span className="link-parent">
                            <ActionButton
                                label={linkTxt}
                                variant="LowEmphasis"
                                element="a"
                                href={linkUrl}
                                externalSite
                                developmentUrl="./assets/able-sprites.svg"
                                target="_blank"
                                className="inlineAnchor"
                            />
                        </span>
                    }
                />
            );
        }
    };

    return <div>{generateInlineLinkTxt()}</div>;
};

export default ExternalLink;
