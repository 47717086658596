import "./help-information-style.scss";

const NetworkAvailabilityInfo = () => {
    return (
        <div className="row">
            <h2 className="info">Availability</h2>
            <ul className="justify-content">
                <li>This is the monthly availability of our public 4G network.</li>
                <li>
                    We measure availability as a percentage of total number of 4G cells available nationally for the
                    month (or at a state level where specifically agreed). The service level target is 99.1% nationally
                    (or per state where agreed).
                </li>
            </ul>
        </div>
    );
};

export default NetworkAvailabilityInfo;
