import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { NoActiveSessionsIcon } from "../../../common/components/able/noactivesessions_icon";
import { SuccessIcon } from "../../../common/components/able/success_icon";
import { ActiveSessionIcon } from "../../../common/components/able/activesession_icon";
import { fetchSubscriptionActiveStatusById } from "../../../apis/subscriptions-apis";
import dayjs from "../../../utils/dayjs-util";

const defaultLastActiveStatus = { text: "Unknown", icon: "Inactive" };

const getLastActiveState = (status, timeStamp) => {
    let { text: statusText, icon: statusIcon } = defaultLastActiveStatus;

    if (status !== defaultLastActiveStatus.text && timeStamp > 0) {
        const currentDate = dayjs();
        const lastActiveTimestamp = dayjs.unix(timeStamp);
        const timeDiffInMins = currentDate.diff(lastActiveTimestamp, "minute");

        const isLastSeenRecently = timeDiffInMins <= 90;
        // 1 day = 1440 mins = 60 mins/hrs * 24 hrs/day
        const isLastSeenToday = timeDiffInMins > 90 && timeDiffInMins <= 1440;
        const isLastSeenYesterday = timeDiffInMins > 1440 && timeDiffInMins <= (1440 * 2);
        const isLastSeen2DaysAgo = timeDiffInMins > (1440 * 2) && timeDiffInMins <= (1440 * 3);

        if (isLastSeenRecently) {
            statusText = timeDiffInMins === 0
                ? '< 1 min ago'
                : `${timeDiffInMins} min${timeDiffInMins > 1 ? 's' : ''} ago`;
            statusIcon = "Active";
        } else {
            if (isLastSeenToday) {
                statusText = "Today";
                statusIcon = "Active";
            } else if (isLastSeenYesterday) {
                statusText = "Yesterday";
                statusIcon = "Stale";
            } else if (isLastSeen2DaysAgo) {
                statusText = "2 days ago";
                statusIcon = "Stale";
            } else {
                statusText = "> 2 days ago";
                statusIcon = "Stale";
            }
        }
    }

    return { text: statusText, icon: statusIcon };
};

const SubscriptionStatus = ({ subscriptionDetails }) => {
    const [lastActiveStatus, setLastActiveStatus] = useState(defaultLastActiveStatus);
    const { data: subscriptionStatusDetails, isLoading } = useQuery(
        ["subscription status", subscriptionDetails.id],
        () => fetchSubscriptionActiveStatusById(subscriptionDetails.id)
    );

    useEffect(() => {
        if (subscriptionStatusDetails) {
            const status = subscriptionStatusDetails?.status ?? defaultLastActiveStatus.text;
            const timeStamp = +subscriptionStatusDetails?.latestConnection;
            setLastActiveStatus(getLastActiveState(status, timeStamp));
        }
    }, [subscriptionStatusDetails]);

    return (
        <>
            <dl className="flex-dl-active-status">
                <div className="div-container">
                    <dt className="service-status">Last active:</dt>
                    {!isLoading && (
                        <dd className="service-status-result">
                            {lastActiveStatus.icon === "Inactive" ? (
                                <NoActiveSessionsIcon developmentURL="/assets/able-sprites.svg" />
                            ) : (lastActiveStatus.icon === "Active") ? (
                                <SuccessIcon developmentURL="/assets/able-sprites.svg" />
                            ) : (
                                <ActiveSessionIcon developmentURL="/assets/able-sprites.svg" />
                            )}
                            {lastActiveStatus.text}
                        </dd>
                    )}
                    {isLoading && <span>Loading...</span>}
                </div>
            </dl>
        </>
    );
};

export default SubscriptionStatus;
