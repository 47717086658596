import { makeApiCall } from "../utils/request-util";
import { decodeToken } from "../utils/token-util";


export const fetchTenancyData = async (opts = {}) => {
    const token = sessionStorage.getItem("id_token");

    const { cidn } = decodeToken(token);

    let options = {
        uri: "/account/v1/tenants",
        params: { cidn: cidn },
        ...opts
    }

    return await makeApiCall(options)
}


export const fetchActiveDevicesCount = async (tenancyId, opts) => {
    let options = {
        uri: `/inventory/v1/tenants/${tenancyId}/devices/count`,
        ...opts
    }

    return await makeApiCall(options)
}