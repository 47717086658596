import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import "telstra-ui/resources/telstraui.css";
import "telstra-ui/resources/themes/pacific.css";
import "@able/web/dist/able-web.css";
import "@able/react/dist/able-react.min.css";
import App from './App';
import AppProvider from "./AppProvider";


const root = createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<AppProvider>
			<App />
		</AppProvider>
	</StrictMode>
);
