import "./throughput-values-component-style.scss";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { roundOffThroughputValue } from "../../../../utils/throughput-data-util";

const ThroughputValues = (props) => {
    let maxThroughput = 0;
    let minThroughput = 0;

    if (props.subscriptionThroughput) {
        const throughputList = props.subscriptionThroughput.map((subscription) => subscription.throughput.downLink);
        maxThroughput = Math.max(...throughputList);
        maxThroughput = roundOffThroughputValue(maxThroughput);
        minThroughput = Math.min(...throughputList);
        minThroughput = roundOffThroughputValue(minThroughput);
    }

    return (
        <dl aria-label="Service information, throughput values list.">
            <dt className="highest-throughput">
                <SvgIcon className="custom-svg-arrow-up" name="icon-system-arrow1-up" />
                {maxThroughput === "-Infinity" ? (
                    <span className="downlink-value">No data available</span>
                ) : (
                    <span className="downlink-value">{maxThroughput}Mbps</span>
                )}
            </dt>
            <dd className="period-info">
                <span>Highest throughput in current {props.period} period</span>
            </dd>

            <dt className="lowest-throughput">
                <SvgIcon className="custom-svg-arrow-down" name="icon-system-arrow1-down" />
                {isNaN(minThroughput) ? (
                    <span className="downlink-value">No data available</span>
                ) : (
                    <span className="downlink-value">{minThroughput}Mbps</span>
                )}
            </dt>
            <dd className="period-info">
                <span>Lowest throughput in current {props.period} period</span>
            </dd>
        </dl>
    );
};

export default ThroughputValues;
