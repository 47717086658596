import "./help-information-style.scss";

const DeviceSummaryInfo = () => {
    return (
        <div className="row">
            <h2 className="info">Device Summary</h2>
            <ul className="justify-content">
                <li>
                	Provides a breakdown by device category for LANES services seen active on the network.
                </li>
                <li>
                	Please note that routers with embedded modules will typically be categorised as “Other devices”.
                </li>
            </ul>
        </div>
    );
};

export default DeviceSummaryInfo;
