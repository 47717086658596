import dayjs from "./dayjs-util";

export const formatAvailabilityList = (data) => {

    const monthList = [];

    for (let month = 0; month < 12; month++ ) {
        monthList.push(dayjs().subtract(month, "month").format("MMM YY").valueOf().toUpperCase());
    }

    const availableMonths = [];

    // Group data by date
    const updatedData = {};
    data.forEach(item => {
        const month = dayjs(item.timestamp).format("MMM YY").valueOf().toUpperCase();
        availableMonths.push(month);
        updatedData[month] = item;
    })

    const finalDataList = monthList.map(month => {
        if(availableMonths.includes(month)) {
            return {
                availability: updatedData[month].availability,
                availabilityTarget: updatedData[month].availabilityTarget,
                timestamp: updatedData[month].timestamp,
                month
            }
        }

        return {
            availability: null,
            availabilityTarget: null,
            timestamp: null,
            month
        }
    })

    return finalDataList.reverse();

}