import "./chart-information.component-style.scss";

const EnterpriseInfo = () => {
    return (
        <div className="row">
            <h2 className="info">Enterprise service info:</h2>
            <ul className="justify-content">
                <li>
                    The average speed improvement is the average speed received with LANES during active use.
                </li>
                <li>
                    Average speed without LANES is the predicted speed without LANES. This is based on the (daily or hourly) average speed 
                    that we estimate a user on our network in similar radio conditions would have experienced without LANES, using national 
                    network performance data.
                </li>
                <li>
                    LANES reporting shows the average received speed during active use (measured daily or hourly). 
                    Note that times of inactivity (such as when the user's device is in standby on their desk or in their pocket, 
                    or when connected to Wi-Fi) are not counted in this measurement. Please note that these measurements are different 
                    to speed-testing tools like Ookla's Speed Test app, which measure the limits of connection over a very short period 
                    (typically within 30 seconds). As such, results from speed testing tools may differ to the average active use speeds 
                    outlined here.
                </li>
            </ul>
        </div>
    );
};

export default EnterpriseInfo;
