import React, { useEffect } from "react";
import { Icon, Divider } from "@able/react";
import useStore from "../../../store/app-store";
import { PageHeading } from "../../../common/components/page-heading/page-heading";
import SubscriptionStatus from "./subscription-status-component";
import WelcomeDetails from "../../dashboard/components/welcome-details-component";

import "./subscription-information-component-style.scss";

const useSubscriptionData = () => {
    const tenancyData = useStore((state) => state.tenancyData);
    const fetchTenancy = useStore((state) => state.fetchTenancy);
    

    // You should consider collapsing this into a single useEffect
    useEffect(() => {
        const controller = new AbortController();

        if (tenancyData === null) {
            fetchTenancy({ signal: controller.signal }).catch((e) => console.error(e));
        }

        return () => controller.abort();
    }, [tenancyData, fetchTenancy]);    

    return { tenancyData };
};

const SubscriptionInformation = ({ subscriptionDetails }) => {
    const { tenancyData } = useSubscriptionData();
    return (
        <>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <PageHeading content={`Service - ${subscriptionDetails?.serviceNumber || ""}`} />
                <WelcomeDetails tenancyName={tenancyData?.tenancyName} />
            </div>
            <SubscriptionStatus subscriptionDetails={subscriptionDetails}/>
            <Divider />
            <dl className="flex-dl" aria-label="subscription information">
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                        Service number
                    </dt>
                    <dd>{subscriptionDetails?.serviceNumber || "-"}</dd>
                </div>
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                        IMSI
                    </dt>
                    <dd>{subscriptionDetails?.subscriptionId || "-"}</dd>
                </div>
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="SIM" developmentUrl="/assets/able-sprites.svg" />
                        SIM serial
                    </dt>
                    <dd>{subscriptionDetails?.simId || "-"}</dd>
                </div>
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                        Device
                    </dt>
                    <dd>
                        {subscriptionDetails?.device
                            ? subscriptionDetails?.device.name + " " + subscriptionDetails?.device.model
                            : "Unknown"}
                    </dd>
                </div>
            </dl>
            <Divider />
        </>
    );
};

export default SubscriptionInformation;
