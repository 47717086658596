import { Pictograms } from "telstra-ui/components/svg/Pictograms";
import "./chart-information.component-style.scss";

const ThroughputChartNoResults = (props) => {
    return (
        <>
            <div className="row no-result-div">
                <span className="no-result-found">No results found</span>
                <br />
                <span className="no-result-message">
                    We were not able to find any data for this customer or time period.
                </span>
                <br />
                <Pictograms name="picto-search" className="picto-svg-class picto-sizing" />
            </div>
        </>
    );
};

export default ThroughputChartNoResults;
