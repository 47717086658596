import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { fetchServiceImprovementData } from "../../../apis/subscriptions-apis";
import SummaryCard from "./SummaryCard/SummaryCard";

const SpeedUpliftCard = ({ tenancy }) => {
    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const { data: servicesImprovementDetails, isLoading } = useQuery(["ServiceImprovement", tenancy], () =>
        fetchServiceImprovementData(tenancy?.tenancyId)
    );

    const calculateCumulativeAverage = useCallback(() => {
        let cumulativeObj = servicesImprovementDetails.reduce(
            (prev, curr) => {
                if (curr["range"] !== "Unknown") {
                    prev.cumulativeDeviation += curr["cumulativeDeviation"];
                    prev.cumulativeCount += curr["value"];
                }
                return prev;
            },
            { cumulativeDeviation: 0, cumulativeCount: 0 }
        );
        return cumulativeObj.cumulativeDeviation > 0
            ? parseFloat((cumulativeObj.cumulativeDeviation / cumulativeObj.cumulativeCount).toFixed(2))
            : 0;
    }, [servicesImprovementDetails]);

    function getSpeedUpliftText(range) {
        if (range?.min === 0 && range.max === 15) return "<15% uplift";
        if (range?.min === 15 && range.max === 100) return "15-100% uplift";
        if (range?.min === 100 && range.max === 0) return ">100% uplift";
    }

    const headers = ["Uplift tier", "Services"];
    const tooltipContent = (
        <>
            The speed uplift figure refers to the estimated increase in downlink throughput (Mbps) averaged across all
            active user data sessions in the reporting period. Learn more about{" "}
            <Link to="/help-and-support#speed-uplift-info">speed uplift</Link>
        </>
    );

    useEffect(() => {
        if (servicesImprovementDetails !== undefined && servicesImprovementDetails.length > 0) {
            setRows(
                servicesImprovementDetails.flatMap((serviceImprovement) =>
                    serviceImprovement.range.min !== -1 && serviceImprovement.range.max !== -1
                        ? [[getSpeedUpliftText(serviceImprovement.range), serviceImprovement.value]]
                        : []
                )
            );
            setTotalCount(calculateCumulativeAverage());
        }
    }, [calculateCumulativeAverage, servicesImprovementDetails]);

    return (
        <SummaryCard
            showLoading={isLoading}
            title="Speed uplift"
            subtitle="Active services (30 days)"
            tooltipLabel="Speed uplift information"
            tooltipContent={tooltipContent}
            loadingText="Loading data..."
            totalCount={totalCount}
            totalCountTitle="Average service uplift"
            actionButtonLabel="View all services"
            actionButtonAriaLabel="View all services speed uplift"
            actionButtonUrl="/services"
            showPercent={true}
            data={{ headers, rows }}
        />
    );
};

export default SpeedUpliftCard;
