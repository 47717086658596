import "./card-style.scss";
import "./outages-card-component-style.scss";
import ExternalLink from "./external-links-component";

const NetworkOutagesCard = () => {
    return (
        <div className="card">
            <div className="outage-content">
                <div className="top-flex">
                    <div className="card-header">
                        <h2 className="header-txt">Network maintenance</h2>
                    </div>
                    <div className="outages-txt">
                        <span>
                            Check if your location is currently experiencing planned maintenance or a network
                            maintenance.
                        </span>
                    </div>
                </div>
                <div className="outage-link">
                    <ExternalLink
                        preLinkTxt=""
                        className="outage-link-padding"
                        linkTxt="Telstra Outages"
                        linkUrl="https://outages.telstra.com.au/#/mobile"
                    />
                </div>
            </div>
        </div>
    );
};

export default NetworkOutagesCard;
